.loader {
    display:inline-block;
    height: 100px;
    width: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -50px;
    margin-left: -50px;
    border: 6px solid #fff;
    border-right-color: #16364a;
    border-top-color: #16364a;
    border-radius: 100%;
    animation: spin 800ms infinite linear;
    z-index:9999;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }