body {
  margin: 0;
}
#main-content {
  overflow: hidden;
}
/*Login Page*/
.admin-login .MuiAvatar-root.MuiAvatar-circular {
  display: none;
}

.admin-login form + div > .MuiButtonBase-root {
  margin-left: 9px;
  width: calc(100% - 18px);
  max-width: 100%;
}
.login-input .MuiTextField-root {
  margin-top: 0.8rem;
}
.login-input .MuiCardActions-root {
  padding-top: 15px;
}
.login-input .MuiFormHelperText-root {
  position: absolute;
  top: 100%;
}
/*Table Image Column*/
.homeimg-wrapper {
  width: 160px;
  height: 130px;
}
.homeimg-mortage {
  width: 160px;
}
body .homeimg-mortage img {
  max-width: 100%;
  width: 100%;
  margin: 0;
  max-height: 140px;
  height: 100%;
}
body .homeimg-wrapper img {
  max-width: 100%;
  width: 100%;
  margin: 0;
  max-height: 140px;
  height: 100%;
}
/*sidebar*/
.MuiListItemIcon-root.rotated {
  transition: all 0.3s linear;
  transform-origin: center;
  width: 25px;
  height: 25px;
  min-width: auto;
  margin-right: 15px;
}
.openRotated {
  transform: rotate(180deg);
  justify-content: flex-end;
  transition: all 0.3s linear;
}
/*post page*/
.post-table {
  table-layout: fixed;
}
.post-table svg {
  cursor: pointer;
}
.post-table svg:first-child {
  margin-right: 15px;
  height: 22px;
}
.post-table .carousel .slider .slide img,
.post-table .first-slide {
  width: 160px;
  height: 100%;
  object-fit: cover;
}
.post-table .carousel ul.control-dots .dot {
  background: #fff;
  opacity: 0.5;
}
.post-table .carousel ul.control-dots .dot.selected {
  opacity: 1;
}
.post-table .carousel.carousel-slider .control-arrow,
.post-table .carousel.carousel-slider .control-arrow {
  opacity: 1;
  color: #333;
}
.post-table .carousel.carousel-slider .control-arrow:hover {
  background: transparent;
}
.post-table .carousel.carousel-slider .control-arrow:before {
  border-left-color: #333;
  border-right-color: #333;
}
.ql-snow .ql-tooltip {
  left: 0 !important;
}
.post-pagination {
  justify-content: end;
}
.post-pagination button:last-child {
  margin-left: 10px;
}
/*Message*/
.MuiAvatar-colorDefault.msg-avtar {
  background: #5589c7;
  color: #fff;
  font-size: 15px;
}
.MuiAvatar-colorDefault.msg-avtar-active {
  background: #fff;
  color: #5589c7;
  font-size: 15px;
}
/*Search box*/
.MuiToolbar-gutters input {
  width: 500px;
}
/*Hide duplicate division error message*/
.ra-input-undefined ul + .Mui-error {
  display: none;
}
[class^="RaAutocompleteSuggestionList-suggestionsContainer-"] span,
[class^="RaAutocompleteSuggestionList-suggestionsContainer-"] strong {
  font-weight: normal;
}

.print-div {
  display: none;
}
.print-table {
  display: none;
}
.print-table table,
.form-table table {
  width: 100%;
}
.print-table table,
.print-table th,
.print-table td,
.form-table table,
.form-table th,
.form-table td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px 10px;
  box-sizing: border-box;
  vertical-align: top;
  word-break: break-all;
}
.table-s-no {
  width: 50px;
}
.table-item {
  max-width: 200px;
}
.table-title {
  width: 150px;
}
.table-response,
.table-trade,
.table-status {
  width: 87px;
  text-align: center;
}
.form-table {
  display: block;
}
.upload-link {
  color: white;
  background-color: #3f51b5;
  display: inline-block;
  padding: 6px 16px;
  margin: 0px;
  transition: background-color 0.3s, color 0.3s;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.upload-link:hover {
  background-color: rgb(69, 24, 181);
  color: white;
  cursor: pointer;
}
@media print {
  .print-table {
    display: block;
  }
  .form-table {
    display: none;
  }
}

.upload-btn-wrapper {
  position: relative;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  padding: 8px;
  background: #fafafa;
  text-align: center;
  margin-bottom: 16px;
  width: 100%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  label {
    display: block;
    cursor: pointer;
    width: 100%;
  }
}

#upload {
  position: absolute;
  opacity: 0;
  inset: 0;
  width: 100%;
  cursor: pointer;
  z-index: -1;
}

.uploaded-docs {
  display: grid;
  grid-template-columns: 20px 250px 400px auto;
  align-items: center;
  gap: 10px;
  align-items: center;
  border-radius: 50px;
}

.uploaded-docs-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.truncate {
  margin-left: 8;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
